
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";

export default defineComponent({
  name: "AccountNavComponent",
  setup() {
    const { t, te } = useI18n({ useScope: "global" });
    const route = useRoute();
    const router = useRouter();
    const storeX = useStore();

    const isActive = (currentRoute: any) => {
      if (currentRoute == route.name) {
        return true;
      }
      return false;
    };

    const getUrl = (path) => {
      return storeX.state.storeCode + path;
    };

    const nav = (path) => {
      router.push(path);
    };

    return {
      t,
      te,
      isActive,
      getUrl,
      storeX,
      nav,
    };
  },
});


import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonLabel,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import settingsApp from "@/libs//settings";
import httpApp from "@/libs/http";
import { useGlobalFunctions } from "@/libs/globalFunctions";

import { useStore } from "@/store";
import VendorNavComponent from "./VendorNavComponent.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";

export default defineComponent({
  name: "Become Vendor",
  setup() {
    const { t, tm, te } = useI18n();
    const storeX = useStore();
    const router = useRouter();
    const { openToast } = useGlobalFunctions();
    const vendorFieldsData = ref(null);
    const isvendor = ref(false);
    const isApproved = ref(false);

    const saveVendorAction = (event) => {
      event.preventDefault();
      const form = document.querySelector("#vendorForm") as HTMLFormElement;
      const data = new FormData(form);
      httpApp
        .sendPostFormData(settingsApp.getEndpointUrl("saveVendor"), data)
        .then(async (response: any) => {
          if (response.success) {
            openToast(response.message, "success");
            router.replace("/");
          } else {
            if (te("apiMessages.codes." + response.error.code)) {
              openToast(
                t("apiMessages.codes." + response.error.code),
                "danger"
              );
            } else {
              openToast(response.error.message, "danger");
            }
          }
        })
        .catch((error) => {
          console.log(error, "error");
          openToast(error.message, "danger");
        });
    };
    function ionViewWillEnter() {
      if (storeX.state.sellerData) {
        vendorFieldsData.value = storeX.state.sellerData.vendor_data;
        isvendor.value = storeX.state.sellerData.is_vendor;
        isApproved.value = storeX.state.sellerData.is_approved;
      } else {
        vendorFieldsData.value = null;
        isvendor.value = false;
        isApproved.value = false;
      }
      // if (isvendor.value) {
      //   router.push("/vendor/vendor-details");
      // }
    }

    return {
      t,
      tm,
      storeX,
      ionViewWillEnter,
      vendorFieldsData,
      isvendor,
      isApproved,
      saveVendorAction,
    };
  },
  components: {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonLabel,
    MainHeader,
    VendorNavComponent,
    MainFooter,
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "main pages" }
const _hoisted_2 = { class: "page-content pt-100 pb-100" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-lg-10 m-auto" }
const _hoisted_6 = { class: "row d-block" }
const _hoisted_7 = { class: "col-md-3 float-start" }
const _hoisted_8 = { class: "col-md-9 float-start positionRelative account" }
const _hoisted_9 = { class: "card" }
const _hoisted_10 = { class: "card-header" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-lg-12" }
const _hoisted_13 = { class: "mb-0" }
const _hoisted_14 = { class: "card-body form-group" }
const _hoisted_15 = ["value"]
const _hoisted_16 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_VendorNavComponent = _resolveComponent("VendorNavComponent")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_MainFooter = _resolveComponent("MainFooter")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_MainHeader),
          _createElementVNode("main", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_VendorNavComponent)
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("div", _hoisted_11, [
                              _createElementVNode("div", _hoisted_12, [
                                _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.t("account.becomeVendor")), 1)
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("form", {
                              id: "vendorForm",
                              enctype: "multipart/form-data",
                              onSubmit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.saveVendorAction($event)))
                            }, [
                              _createElementVNode("input", {
                                type: "hidden",
                                name: "store",
                                value: _ctx.storeX.state.storeCode
                              }, null, 8, _hoisted_15),
                              _createVNode(_component_ion_list, { class: "formList" }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vendorFieldsData, (vendorFieldData, FieldDataIndx) => {
                                    return (_openBlock(), _createElementBlock("div", { key: FieldDataIndx }, [
                                      (vendorFieldData.type === 'text')
                                        ? (_openBlock(), _createBlock(_component_ion_item, {
                                            key: 0,
                                            class: "form-control",
                                            lines: "none"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_input, {
                                                name: vendorFieldData.key,
                                                value: vendorFieldData.value,
                                                clearInput: "true",
                                                inputmode: "text",
                                                type: "text",
                                                autocomplete: "given-name",
                                                placeholder: vendorFieldData.label
                                              }, null, 8, ["name", "value", "placeholder"])
                                            ]),
                                            _: 2
                                          }, 1024))
                                        : _createCommentVNode("", true),
                                      (vendorFieldData.type === 'select')
                                        ? (_openBlock(), _createBlock(_component_ion_item, {
                                            key: 1,
                                            class: "form-control",
                                            lines: "none"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_label, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(vendorFieldData.label), 1)
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createVNode(_component_ion_select, {
                                                name: vendorFieldData.key,
                                                interface: "popover"
                                              }, {
                                                default: _withCtx(() => [
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(vendorFieldData.options, (option, optionsIndx) => {
                                                    return (_openBlock(), _createBlock(_component_ion_select_option, {
                                                      key: 'option_' + optionsIndx,
                                                      textContent: _toDisplayString(option.label),
                                                      value: option.value
                                                    }, null, 8, ["textContent", "value"]))
                                                  }), 128))
                                                ]),
                                                _: 2
                                              }, 1032, ["name"])
                                            ]),
                                            _: 2
                                          }, 1024))
                                        : _createCommentVNode("", true),
                                      (vendorFieldData.type === 'image')
                                        ? (_openBlock(), _createBlock(_component_ion_item, {
                                            key: 2,
                                            class: "form-control",
                                            lines: "none"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_label, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(vendorFieldData.label), 1)
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createVNode(_component_ion_input, {
                                                class: "ion-float-end",
                                                name: vendorFieldData.key,
                                                value: vendorFieldData.value,
                                                clearInput: "true",
                                                inputmode: "file",
                                                type: "file",
                                                placeholder: vendorFieldData.label
                                              }, null, 8, ["name", "value", "placeholder"])
                                            ]),
                                            _: 2
                                          }, 1024))
                                        : _createCommentVNode("", true),
                                      (vendorFieldData.type === 'textarea')
                                        ? (_openBlock(), _createBlock(_component_ion_item, {
                                            key: 3,
                                            class: "form-control",
                                            lines: "none"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_textarea, {
                                                name: vendorFieldData.key,
                                                value: vendorFieldData.value,
                                                clearInput: "true",
                                                inputmode: "textarea",
                                                type: "textarea",
                                                placeholder: vendorFieldData.label
                                              }, null, 8, ["name", "value", "placeholder"])
                                            ]),
                                            _: 2
                                          }, 1024))
                                        : _createCommentVNode("", true)
                                    ]))
                                  }), 128)),
                                  _createElementVNode("button", {
                                    class: "btn btn-heading btn-block",
                                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.saveVendorAction($event)))
                                  }, _toDisplayString(_ctx.t("account.save")), 1)
                                ]),
                                _: 1
                              })
                            ], 32)
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("footer", _hoisted_16, [
            _createVNode(_component_MainFooter)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}